import React from "react"
import { useHomeContext } from "../../context/home_context"

const Packages = () => {
  const { home } = useHomeContext()
  const { packages } = home

  return (
    <>
      <h3>Packages:</h3>
      <p>
        {packages === "standard"
          ? "You currently have the Standard Home package. You are eligible to upgrade to the Enhanced package, which would allow for claim forgiveness, a deductible that decreases over time, and other benefits. Give us a call if you’d like to discuss adding the Enhanced Home package to your policy."
          : packages === "enhanced"
          ? "You currently have the Enhanced Home package. This gives you claim forgiveness, an annual bonus rebate if you don’t have any claims during the policy period and a deductible that reduces over time. If you have any questions about the Enhanced Home package, please give us a call."
          : null}
      </p>
      <br />
    </>
  )
}

export default Packages
