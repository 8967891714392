import React from "react"
import { useHomeContext } from "../../context/home_context"
import { formatPrice } from "../../utils/helper"

const BuildingStructure = () => {
  const { home } = useHomeContext()
  const { buildingStructureReimbursement, dwelling } = home

  return (
    <>
      <h3>Building Structure Reimbursement Extended Limits:</h3>
      <p>
        {buildingStructureReimbursement === "20%"
          ? `Your policy currently extends 20% of coverage to your dwelling limit, if rebuild or repair costs exceed ${formatPrice(
              dwelling
            )}. If you feel that this is not enough coverage, we can increase it to 40%. Please call or reply to this email to discuss increasing this coverage.`
          : buildingStructureReimbursement === "40%"
          ? `Your policy currently extends 40% of coverage to your dwelling limit, if rebuild or repair costs exceed ${formatPrice(
              dwelling
            )}.`
          : `In the event of a covered loss, the addition of Reimbursement Extended Limits (BSRL) can extend your coverage by 20% or 40%, if rebuild or repair costs exceed ${formatPrice(
              dwelling
            )}. You currently do not have this extra coverage on your policy. Please call or reply to this email to discuss adding BSRL to your policy.`}
      </p>
      <br />
    </>
  )
}

export default BuildingStructure
