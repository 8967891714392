import React from "react"
import { useHomeContext } from "../../context/home_context"

const Water = () => {
  const { home } = useHomeContext()
  const { waterBackup } = home

  return (
    <>
      <h3>Water Backup:</h3>
      <p>
        {`Water Backup coverage covers the removal of subsurface water that backs up into your house from a city sewer line, sump pump, sump pump well or another system. ${
          waterBackup
            ? `You currently have ${waterBackup} of coverage. Please call us if you would like to add more coverage to your Home policy.`
            : "You do not currently have this coverage. Please call us to add this coverage to your Home policy."
        }`}
      </p>
      <br />
    </>
  )
}

export default Water
