import React from "react"
import { useHomeContext } from "../../context/home_context"
import { formatPrice } from "../../utils/helper"

const Liability = () => {
  const { home } = useHomeContext()
  const { familyLiability } = home

  return (
    <>
      <h3>Family Liability Protection:</h3>
      <p>
        {`Your Family Liability coverage is available to you in the event that
        you or another insured person in your house is legally obligated to pay
        another party for their bodily injuries or damage done to their
        property. You currently have ${formatPrice(
          familyLiability
        )} of Family Liability
        coverage. We recommend that you carry an Umbrella policy that would
        protect you from other liability exposures. Please call us to discuss
        adding an Umbrella policy to protect your assets.`}
      </p>
      <br />
    </>
  )
}

export default Liability
