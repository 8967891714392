import React from "react"
import { useHomeContext } from "../../context/home_context"

const Roof = () => {
  const { home } = useHomeContext()
  const { roofSurface } = home

  return (
    <>
      <h3>Roof Surface Extended Coverage:</h3>
      <p>{roofSurface}</p>
      <br />
    </>
  )
}

export default Roof
