import React from "react"
import { useHomeContext } from "../../context/home_context"

const HomeCoverage = () => {
  const { homeData, home, handleHomeChange } = useHomeContext()
  const data = homeData.filter(item => item.fields.selection)

  return (
    <div className="row">
      {/* home coverage input */}
      {data.map((item, index) => {
        const { name, title, isRequired, selection } = item.fields

        return (
          <div key={index} className="dwelling-container">
            <label htmlFor={name} className="label">
              <h3>{title}</h3>
            </label>
            <select
              name={name}
              value={home[name]}
              onChange={handleHomeChange}
              required={isRequired}
            >
              {selection.map((value, index) => {
                return (
                  <>
                    <option key={index} value={value}>
                      {value}
                    </option>
                  </>
                )
              })}
            </select>
          </div>
        )
      })}
    </div>
  )
}

export default HomeCoverage
