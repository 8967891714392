import React, { useState, useContext } from "react"
import homeCoverage from "../assets/data/homeCoverage"

const HomeContext = React.createContext()

const HomeProvider = ({ children }) => {
  // State
  const [currentStep, setCurrentStep] = useState(0)

  const [homeData, setHomeData] = useState(homeCoverage)
  const [home, setHome] = useState({
    packages: "",
    dwelling: "",
    familyLiability: "",
    guestMedical: "",
    personalProperty: "",
    otherStructures: "",
    additionalLivingExpense: "",
    buildingStructureReimbursement: "",
    buildingCodesCoverage: "",
    roofSurface: "",
    windHailDeductible: "",
    allPerilDeductible: "",
    waterBackup: "",
    extendedPremises: "",
    lossAssessments: "",
  })

  const handleHomeChange = e => {
    let name = e.target.name
    let value = e.target.value
    let newData = home

    newData[name] = value

    setHome({ ...newData })
  }

  // Scroll to Top
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    })
  }

  // Form Steps
  const handleNextHomeStep = () => {
    setCurrentStep(prev => prev + 1)
    scrollToTop()
  }
  // previous step in form
  const handlePrevStep = newData => {
    // setVehicles(prev => [...prev, newData])
    setCurrentStep(prev => prev - 1)
    scrollToTop()
  }

  return (
    <HomeContext.Provider
      value={{
        currentStep,
        handlePrevStep,

        handleNextHomeStep,
        handleHomeChange,
        home,
        homeData,
      }}
    >
      {children}
    </HomeContext.Provider>
  )
}

export const useHomeContext = () => {
  return useContext(HomeContext)
}

export { HomeContext, HomeProvider }
