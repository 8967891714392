import React from "react"
import { useHomeContext } from "../../context/home_context"
import { formatPrice, percentOfDwelling } from "../../utils/helper"

const Other = () => {
  const { home } = useHomeContext()
  const { otherStructures, dwelling } = home

  return (
    <>
      <h3>Other Structures Protection:</h3>
      <p>
        {`In the event of a covered loss, we will pay up to ${formatPrice(
          percentOfDwelling(otherStructures, dwelling)
        )}, minus your deductible, to fix or replace any other structures on your property. This may include sheds, a detached garage, fences, etc. If you feel like you need to increase this amount, please give us a call.`}
      </p>
      <br />
    </>
  )
}

export default Other
