import React from "react"
import Main from "../components/home/Main"
import Navbar from "../components/global/Navbar"
import Footer from "../components/global/Footer"
import "../assets/css/main.css"
import { HomeProvider } from "../context/home_context"
import { Auth0Provider } from "@auth0/auth0-react"
import { GlobalProvider } from "../context/global_context"

export default function Home() {
  return (
    <Auth0Provider
      domain={process.env.GATSBY_AUTH0_DOMAIN}
      clientId={process.env.GATSBY_AUTH0_CLIENT_ID}
      redirectUri="https://www.reviewrapidly.com/"
    >
      <GlobalProvider>
        <HomeProvider>
          <main className="main">
            <Navbar />
            <Main />
            <Footer />
          </main>
        </HomeProvider>
      </GlobalProvider>
    </Auth0Provider>
  )
}
