import React from "react"
import { useHomeContext } from "../../context/home_context"

// HOME FORM
const FormOne = () => {
  const { home, handleHomeChange, handleNextHomeStep } = useHomeContext()

  return (
    <form className="form" onSubmit={handleNextHomeStep}>
      <div className="form-center">
        <div className="row">
          <h2>Home</h2>

          {/* Home Packages */}
          <div className="dwelling-container">
            <label htmlFor="packages" className="label">
              <span>Packages:</span>
            </label>
            <select
              className="select-packages"
              name="packages"
              value={home.packages}
              onChange={handleHomeChange}
              required
            >
              <option className="placeholder" value="">
                Choose option
              </option>
              <option value="standard">Standard</option>
              <option value="enhanced">Enhanced</option>
            </select>
          </div>

          {/* Number Input Section */}
          <div className="vehicle-container">
            <div className="field-container">
              {/* Dwelling */}
              <div className="dwelling-container">
                <label htmlFor="dwelling" className="label">
                  <span>Dwelling Coverage:</span>
                </label>
                <input
                  type="number"
                  name="dwelling"
                  value={home.dwelling}
                  onChange={handleHomeChange}
                  required
                />
              </div>
              {/* Personal Property */}
              <div className="dwelling-container">
                <label htmlFor="personalProperty" className="label">
                  <span>Personal Property Protection:</span>
                </label>
                <select
                  name="personalProperty"
                  value={home.personalProperty}
                  onChange={handleHomeChange}
                  required
                >
                  <option value="">Choose option</option>
                  <option value=".75">75%</option>
                  <option value=".70">70%</option>
                  <option value=".65">65%</option>
                  <option value=".60">60%</option>
                  <option value=".55">55%</option>
                  <option value=".50">50%</option>
                  <option value=".45">45%</option>
                  <option value=".40">40%</option>
                </select>
              </div>
              {/* Additional Living Expense */}
              <div className="dwelling-container">
                <label htmlFor="additionalLivingExpense" className="label">
                  <span>Additional Living Expense:</span>
                </label>
                <select
                  name="additionalLivingExpense"
                  value={home.additionalLivingExpense}
                  onChange={handleHomeChange}
                  required
                >
                  <option value="">Choose option</option>
                  <option value=".40">40%</option>
                  <option value=".35">35%</option>
                  <option value=".30">30%</option>
                  <option value=".25">25%</option>
                  <option value=".20">20%</option>
                  <option value=".15">15%</option>
                  <option value=".10">10%</option>
                  <option value=".05">5%</option>
                </select>
              </div>
              {/* Other Structures */}
              <div className="dwelling-container">
                <label htmlFor="otherStructures" className="label">
                  <span>Other Structures Protection:</span>
                </label>
                <select
                  name="otherStructures"
                  value={home.otherStructures}
                  onChange={handleHomeChange}
                  required
                >
                  <option value="">Choose option</option>
                  <option value=".75">75%</option>
                  <option value=".70">70%</option>
                  <option value=".65">65%</option>
                  <option value=".60">60%</option>
                  <option value=".55">55%</option>
                  <option value=".50">50%</option>
                  <option value=".45">45%</option>
                  <option value=".40">40%</option>
                  <option value=".35">35%</option>
                  <option value=".30">30%</option>
                  <option value=".25">25%</option>
                  <option value=".20">20%</option>
                  <option value=".15">15%</option>
                  <option value=".10">10%</option>
                  <option value=".05">5%</option>
                </select>
              </div>
              {/* Family Liability */}
              <div className="dwelling-container">
                <label htmlFor="familyLiability" className="label">
                  <span>Family Liability Protection:</span>
                </label>
                <select
                  name="familyLiability"
                  value={home.familyLiability}
                  onChange={handleHomeChange}
                  required
                >
                  <option value="">Choose option</option>
                  <option value="100000">$100,000</option>
                  <option value="200000">$200,000</option>
                  <option value="300000">$300,000</option>
                  <option value="500000">$500,000</option>
                </select>
              </div>
              {/* Guest Medical */}
              <div className="dwelling-container">
                <label htmlFor="guestMedical" className="label">
                  <span>Guest Medical Protection:</span>
                </label>
                <select
                  name="guestMedical"
                  value={home.guestMedical}
                  onChange={handleHomeChange}
                  required
                >
                  <option value="">Choose option</option>
                  <option value="1000">$1,000</option>
                  <option value="2500">$2,500</option>
                  <option value="5000">$5,000</option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <hr className="line" />
        {/* next button */}
        <div className="btn-container">
          <button className="btn next-btn" type="submit">
            Next
          </button>
        </div>
      </div>
    </form>
  )
}

export default FormOne
