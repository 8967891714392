const homeCoverage = [
  {
    sys: {
      id: "1",
    },
    fields: {
      title: "Packages:",
      name: "packages",
      isRequired: true,
      description: [
        "You currently have the Standard Home package. You are eligible to upgrade to the Enhanced package, which would allow for claim forgiveness, a deductible that decreases over time, and other benefits. Give us a call if you’d like to discuss adding the Enhanced Home package to your policy.",

        "You currently have the Enhanced Home package. This gives you claim forgiveness, an annual bonus rebate if you don’t have any claims during the policy period and a deductible that reduces over time. If you have any questions about the Enhanced Home package, please give us a call.",
      ],
    },
  },
  {
    sys: {
      id: "2",
    },
    fields: {
      title: "Dwelling Coverage:",
      name: "dwelling",
      isRequired: true,
      description: [
        `Your home is currently covered for up to $home.dwelling}. In the event of a covered loss, we will pay up to this amount to either fix or replace your home minus your deductible. Due to market value increases, rising inflation or a workforce shortage, this amount may not be enough to fully replace your home. If you feel that $home.dwelling} isn’t enough, please give us a call and we can look at increasing your coverage amount.`,
      ],
    },
  },
  {
    sys: {
      id: "3",
    },
    fields: {
      title: "Family Liability Protection:",
      name: "famililyLiability",
      isRequired: true,
      description: [
        `Your Family Liability coverage is available to you in the event that you or another insured person in your house is legally obligated to pay another party for their bodily injuries or damage done to their property. You currently have $home.familyLiability} of Family Liability coverage. We recommend that you carry an Umbrella policy that would protect you from other liability exposures. Please call us to discuss adding an Umbrella policy to protect your assets.`,
      ],
    },
  },
  {
    sys: {
      id: "4",
    },
    fields: {
      title: "Guest Medical Protection:",
      name: "guestMedical",
      isRequired: true,
      description: [
        `In the event that someone is injured while on your property, we pay up to $home.guestMedical} for their medical expenses.`,
      ],
    },
  },
  {
    sys: {
      id: "5",
    },
    fields: {
      title: "Personal Property Protection:",
      name: "personalProperty",
      isRequired: true,
      description: [
        `In the event of a covered loss, we will pay up to $home.personalProperty}, minus your deductible, for your personal property that is not permanently attached to your house.

        Please note that if you have electronics that are damaged or stolen from your vehicle, it would not be covered under your Home policy. You would need to request that coverage be added to your auto insurance.`,
      ],
    },
  },
  {
    sys: {
      id: "6",
    },
    fields: {
      title: "Other Structures Protection:",
      name: "otherStructures",
      isRequired: true,
      description: [
        `In the event of a covered loss, we will pay up to $home.otherStructures}, minus your deductible, to fix or replace any other structures on your property. This may include sheds, a detached garage, fence, etc. If you feel like you need to increase this amount, please give us a call.`,
      ],
    },
  },
  {
    sys: {
      id: "7",
    },
    fields: {
      title: "Additional Living Expense:",
      name: "additionalLivingExpense",
      isRequired: true,
      description: [
        `The Additional Living Expense coverage takes care of any expenses incurred if your home becomes uninhabitable due to a covered loss. You currently have $home.additionalLivingExpense} of Additional Living Expense or up to 24 months of coverage.`,
      ],
    },
  },
  {
    sys: {
      id: "8",
    },
    fields: {
      title: "Building Structure Reimbursement Extended Limits:",
      name: "buildingStructureReimbursement",
      isRequired: true,
      selection: ["none", "20%", "40%"],
      description: [
        `In the event of a covered loss, the addition of Reimbursement Extended Limits (BSRL) can extend your coverage by 20% or 40%, if rebuild or repair costs exceed $home.dwelling}. You currently do not have this extra coverage on your policy. Please call or reply to this email to discuss adding BSRL to your policy.`,

        `Your policy currently extends 20% of coverage to your dwelling limit, if rebuild or repair costs exceed the $home.dwelling}. If you feel that this is not enough coverage, we can increase it to 40%. Please call or reply to this email to discuss increasing this coverage.`,

        `Your policy currently extends 40% of coverage to your dwelling limit, if rebuild or repair costs exceed the $home.dwelling}.`,
      ],
    },
  },
  {
    sys: {
      id: "9",
    },
    fields: {
      title: "Building Codes Coverage:",
      name: "buildingCodesCoverage",
      isRequired: true,
      selection: ["none", "5%", "10%", "20%"],
      description: [
        "Building Codes coverage is an available endorsement that covers increased costs that may be required to comply with local building codes or ordinances. You currently have 5% of your dwelling limit of Building Codes coverage. If you would like to look at increasing this coverage, please either reply to this email or call us.",

        "Building Codes coverage is an available endorsement that covers increased costs that may be required to comply with local building codes or ordinances. You currently have 10% of your dwelling limit of Building Codes coverage. If you would like to look at increasing this coverage, please either reply to this email or call us.",

        "Building Codes coverage is an available endorsement that covers increased costs that may be required to comply with local building codes or ordinances. You currently have 20% of your dwelling limit of Building Codes coverage.",
      ],
    },
  },
  {
    sys: {
      id: "10",
    },
    fields: {
      title: "Roof Surface Extended Coverage:",
      name: "roofSurface",
      isRequired: true,
      selection: ["none", "Yes"],
      description: [""],
    },
  },
  {
    sys: {
      id: "11",
    },
    fields: {
      title: "Wind and Hail Deductible:",
      name: "windHailDeductible",
      isRequired: true,
      selection: [
        "none",
        "$1,000",
        "$1,500",
        "$2,000",
        "$2,500",
        "$3,000",
        "$5,000",
        "$7,500",
        "$10,000",
        "1%",
        "2%",
        "5%",
      ],
      description: [
        "In the event of a covered loss due to wind or hail, we will pay to restore the value of your property damaged at the time of loss, minus your deductible. Your Wind and Hail deductible is currently set at $1,000.",

        "In the event of a covered loss due to wind or hail, we will pay to restore the value of your property damaged at the time of loss, minus your deductible. Your Wind and Hail deductible is currently set at $1,500.",

        "In the event of a covered loss due to wind or hail, we will pay to restore the value of your property damaged at the time of loss, minus your deductible. Your Wind and Hail deductible is currently set at $2,000.",

        "In the event of a covered loss due to wind or hail, we will pay to restore the value of your property damaged at the time of loss, minus your deductible. Your Wind and Hail deductible is currently set at $2,500.",

        "In the event of a covered loss due to wind or hail, we will pay to restore the value of your property damaged at the time of loss, minus your deductible. Your Wind and Hail deductible is currently set at $3,000.",

        "In the event of a covered loss due to wind or hail, we will pay to restore the value of your property damaged at the time of loss, minus your deductible. Your Wind and Hail deductible is currently set at $5,000.",

        "In the event of a covered loss due to wind or hail, we will pay to restore the value of your property damaged at the time of loss, minus your deductible. Your Wind and Hail deductible is currently set at $7,500.",

        "In the event of a covered loss due to wind or hail, we will pay to restore the value of your property damaged at the time of loss, minus your deductible. Your Wind and Hail deductible is currently set at $10,000.",

        `In the event of a covered loss due to wind or hail, we will pay to restore the value of your property damaged at the time of loss, minus your deductible. Your Wind and Hail deductible is currently set at 1% of $home.dwelling}.`,

        `In the event of a covered loss due to wind or hail, we will pay to restore the value of your property damaged at the time of loss, minus your deductible. Your Wind and Hail deductible is currently set at 2% of $home.dwelling}.`,

        `In the event of a covered loss due to wind or hail, we will pay to restore the value of your property damaged at the time of loss, minus your deductible. Your Wind and Hail deductible is currently set at 5% of $home.dwelling}.`,
      ],
    },
  },
  {
    sys: {
      id: "12",
    },
    fields: {
      title: "All Peril Deductible:",
      name: "allPerilDeductible",
      isRequired: true,
      selection: [
        "none",
        "$500",
        "$1,000",
        "$1,500",
        "$2,000",
        "$2,500",
        "$3,000",
        "$5,000",
        "$7,500",
        "$10,000",
        "1%",
        "2%",
        "5%",
      ],
      description: [
        "In the event of a covered loss, we will pay to restore the value of your property damaged at the time of loss minus your deductible. Your All Peril deductible is currently set at $500.",

        "In the event of a covered loss, we will pay to restore the value of your property damaged at the time of loss minus your deductible. Your All Peril deductible is currently set at $1,000.",

        "In the event of a covered loss, we will pay to restore the value of your property damaged at the time of loss minus your deductible. Your All Peril deductible is currently set at $1,500.",

        "In the event of a covered loss, we will pay to restore the value of your property damaged at the time of loss minus your deductible. Your All Peril deductible is currently set at $2,000.",

        "In the event of a covered loss, we will pay to restore the value of your property damaged at the time of loss minus your deductible. Your All Peril deductible is currently set at $2,500.",

        "In the event of a covered loss, we will pay to restore the value of your property damaged at the time of loss minus your deductible. Your All Peril deductible is currently set at $3,000.",

        "In the event of a covered loss, we will pay to restore the value of your property damaged at the time of loss minus your deductible. Your All Peril deductible is currently set at $5,000.",

        "In the event of a covered loss, we will pay to restore the value of your property damaged at the time of loss minus your deductible. Your All Peril deductible is currently set at $7,500.",

        "In the event of a covered loss, we will pay to restore the value of your property damaged at the time of loss minus your deductible. Your All Peril deductible is currently set at $10,000.",

        `In the event of a covered loss, we will pay to restore the value of your property damaged at the time of loss minus your deductible. Your All Peril deductible is currently set at 1% of $home.dwelling}.`,

        `In the event of a covered loss, we will pay to restore the value of your property damaged at the time of loss minus your deductible. Your All Peril deductible is currently set at 2% of $home.dwelling}.`,

        `In the event of a covered loss, we will pay to restore the value of your property damaged at the time of loss minus your deductible. Your All Peril deductible is currently set at 5% of $home.dwelling}.`,
      ],
    },
  },
  {
    sys: {
      id: "13",
    },
    fields: {
      title: "Water Backup:",
      name: "waterBackup",
      isRequired: true,
      selection: ["none", "$5,000", "$10,000", "$25,000", "$50,000"],
      description: [
        "Water Backup coverage covers the removal of subsurface water that backs up into your house from a city sewer line, sump pump, sump pump well or another system. You do not currently have this coverage. Please call us to add this coverage to your Home policy.",

        "Water Backup coverage covers the removal of subsurface water that backs up into your house from a city sewer line, sump pump, sump pump well or another system. You currently have $5,000 of coverage. Please call us if you would like to add more coverage to your Home policy.",

        "Water Backup coverage covers the removal of subsurface water that backs up into your house from a city sewer line, sump pump, sump pump well or another system. You currently have $10,000 of coverage. Please call us if you would like to add more coverage to your Home policy.",

        "Water Backup coverage covers the removal of subsurface water that backs up into your house from a city sewer line, sump pump, sump pump well or another system. You currently have $25,000 of coverage. Please call us if you would like to add more coverage to your Home policy.",

        "Water Backup coverage covers the removal of subsurface water that backs up into your house from a city sewer line, sump pump, sump pump well or another system. You currently have $50,000 of coverage. Please call us if you would like to add more coverage to your Home policy.",
      ],
    },
  },
  {
    sys: {
      id: "14",
    },
    fields: {
      title: "Extended Premises:",
      name: "extendedPremises",
      isRequired: true,
      selection: ["none", "Purchased"],
      description: [
        "If you rent out a portion of your house or have vacant land (not farmland) we should discuss adding Extended Premises to your policy. Please call us at [agencyphone] or respond to this email to add this coverage.",

        "You currently have Extended Premises coverage on your policy. This coverage applies if you rent out a portion of your home or if you have vacant land (not farmland).",
      ],
    },
  },
  {
    sys: {
      id: "15",
    },
    fields: {
      title: "Loss Assessments:",
      name: "lossAssessments",
      isRequired: true,
      selection: ["none", "Purchased"],
      description: [
        "In the event that the homeowner’s association where you live initiates a claim with their master HOA policy, you may be obligated to contribute to that deductible. Depending on your HOA, your portion of that deductible could be as much at $50,000. By adding Loss Assessments to your policy, we will pay as much as $50,000 to your HOA’s master policy’s deductible. You do not currently have this coverage on your policy. If you would like to add it, please give us a call.",

        "In the event that the homeowner’s association where you live initiates a claim with their master HOA policy, you may be obligated to contribute to that deductible. Loss Assessments pay up to $50,000 to cover your portion of the master HOA’s deductible.",
      ],
    },
  },
]

export default homeCoverage
