import React from "react"
import { useHomeContext } from "../../context/home_context"

const Loss = () => {
  const { home } = useHomeContext()
  const { lossAssessments } = home

  return (
    <>
      <h3>Loss Assessments:</h3>
      <p>
        {`In the event that the homeowner’s association where you live initiates a claim with their master HOA policy, you may be obligated to contribute to that deductible. ${
          lossAssessments
            ? "In the event that the homeowner’s association where you live initiates a claim with their master HOA policy, you may be obligated to contribute to that deductible. Loss Assessments pay up to $50,000 to cover your portion of the master HOA’s deductible."
            : "Depending on your HOA, your portion of that deductible could be as much at $50,000. By adding Loss Assessments to your policy, we will pay as much as $50,000 to your HOA’s master policy’s deductible. You do not currently have this coverage on your policy. If you would like to add it, please give us a call."
        }`}
      </p>
      <br />
    </>
  )
}

export default Loss
